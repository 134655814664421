<template>
  <div class="flex auth-page">
    <div>
      <div class="space">
        <div>
          <notifications
            group="change-password"
            class="mt-2"
            :duration="5000"
            classes="vue-notification mr-2 text-base shadow"
          />
          <div class="space-frame">
            <div class="logo flex justify-center mb-4">
              <div class="w-16 h-16 mr-2"><logo-workkami /></div>
              <div class="logo-text">WORKKAMI</div>
            </div>

            <div class="title-text">{{ $t('login.forgotPass.change') }}</div>
            <div class="mt-6">
              <base-input-password
                v-model="newPassword"
                :disabled="loading"
                innerClass="pr-6-i"
                type="password"
                :placeholder="$t('login.forgotPass.new')"
              />
            </div>
            <div class="mt-3">
              <base-input-password
                v-model="confirmPassword"
                :disabled="loading"
                innerClass="pr-6-i"
                type="password"
                name="password"
                :placeholder="$t('login.forgotPass.confirm')"
                :class="{ error: invalidConfirmPassword }"
              />
              <div v-if="error" class="mt-1 mb-6 color-error">{{ error }}</div>
              <base-button
                class="w-full mt-6"
                :color="isCanSubmit ? 'brand' : 'disabled'"
                :disabled="!isCanSubmit"
                :loading="loading"
                type="submit"
                @click="changePassword"
                >{{ $t('login.forgotPass.send') }}</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoWorkkami from '../components/icons/LogoWorkkami';
import LoadingMixin from '../mixins/LoadingMixin';

export default {
  components: {
    LogoWorkkami
  },
  mixins: [LoadingMixin],
  watch: {
    confirmPassword: function(confirmPassword) {
      if (confirmPassword.length) {
        this.isCanSubmit = true;
        if (confirmPassword == this.newPassword) {
          this.error = '';
          this.invalidConfirmPassword = false;
        }
      } else {
        this.isCanSubmit = false;
      }
    }
  },
  data() {
    return {
      loading: false,
      newPassword: '',
      confirmPassword: '',
      isCanSubmit: false,
      invalidConfirmPassword: false,
      error: ''
    };
  },
  methods: {
    async changePassword() {
      if (this.newPassword != this.confirmPassword) {
        this.error = this.$t('login.signup.error.notmatch');
        this.invalidConfirmPassword = true;
      }
      this.onLoading();
      if (this.newPassword == this.confirmPassword) {
        try {
          const payload = {
            password: this.newPassword,
            code: this.$route.params.otpCode,
            email: this.$route.params.email
          };
          const group = 'change-password';
          await this.$store.dispatch('auth/resetPassword', payload);
          this.$notify({
            text: this.$t('notifications.popup.reset'),
            type: 'success',
            group
          });
          setTimeout(() => {
            this.$router.push({ name: 'Boards-List' });
          }, 2000);
        } catch (error) {
          this.error = error?.response?.data?.message;
        }
      }
      this.offLoading();
    }
  }
};
</script>

<style lang="scss" scoped>
.space-frame {
  padding: 15px;
  padding-top: 15px;
}
.title-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-top: 20px;
}
.vue-notification {
  white-space: pre;
}
</style>
